//const swiper = new Swiper(".swiper", {});
//console.log(3, swiper);

import Glide from "@glidejs/glide";
import { createAnimere } from "animere";
import L from "leaflet";

new Glide(".glide.hero", {
	type: "carousel",
	autoplay: 3000,
	animationDuration: 900,
	hoverpause: true,
}).mount();

new Glide(".glide.services", {
	type: "carousel",
	perView: 3,
	breakpoints: {
		800: {
			perView: 2,
		},
		640: {
			perView: 1,
		},
	},
	autoplay: 6000,
	animationDuration: 700,
	hoverpause: true,
	gap: 20,
}).mount();

new Glide(".glide.team", {
	type: "carousel",
	perView: 3,
	breakpoints: {
		800: {
			perView: 2,
		},
		640: {
			perView: 1,
		},
	},
	autoplay: 6000,
	animationDuration: 700,
	hoverpause: true,
	gap: 20,
}).mount();

createAnimere();

var ll = [-34.87757, 138.48973];

var map = L.map("map", {
	center: L.latLng(ll[0], ll[1]),
	zoom: 16,
});

// const map = L.map("map", {
//   center: L.latLng(49.2125578, 16.62662018),
//   zoom: 14,
// });

//var map = L.map("map").setView([51.505, -0.09], 13);

L.tileLayer("https://tile.openstreetmap.org/{z}/{x}/{y}.png", {
	maxZoom: 16,
	attribution: '&copy; <a href="http://www.openstreetmap.org/copyright">OpenStreetMap</a>',
}).addTo(map);

var marker = L.marker(ll).addTo(map);

//var map = L.map("map").setView([51.505, -0.09], 13);

var mobileNav = document.getElementById("navbarNavDropdown");
var hamburger = document.getElementById("hamburger");

mobileNav.addEventListener("hide.bs.collapse", function (e) {
	hamburger.classList.remove("active");
});
mobileNav.addEventListener("show.bs.collapse", function (e) {
	hamburger.classList.add("active");
});

var rellax = new Rellax(".rellax");
console.log(rellax);
